<template>
  <div>
    {{ robe.libelle }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            robes: 'identification/robes'
        }),
        robe(){
            return [...this.robes].find(item => item.uid === this.object.robe)
        }
    }

}
</script>